interface LogoProps {
    width: number
}

export const Logo = (props: LogoProps) => {
    return (
        <button>
            <svg viewBox="0 0 162.4 162.4" xmlns="http://www.w3.org/2000/svg" style={{ width: props.width }}>
                <g
                    transform="matrix(0.3632010221481323, 0, 0, 0.29579102993011475, -105.21931457519531, -70.7804946899414)"
                >
                    <path
                        className="st0"
                        d="M656,464.1c0,0-47.3-47.3-37.8-85.1c0,0-66.2,56.7,28.4,141.8c0,0,47.3,37.8,47.3,104 C693.9,624.8,741.1,577.6,656,464.1z"
                        style={{ fill: '#d79f00', strokeWidth: '0px', strokeOpacity: 0 }}
                    />
                    <path
                        className="st0"
                        d="M289.7,185.2v115.5c25.4,42,37.4,77.5,37.4,77.5c21.7,73.5,56.9,125.2,91.7,160.8c8.3,8.5,16.5,16,24.5,22.7 c0.1,0.1,0.1,0.1,0.1,0.1c3.7,3.1,7.3,6,10.9,8.8c29.2,22.5,64.7,35.4,101.6,36.8c2.5,0.1,4.2,0.1,4.7,0.1 c-2.4,1.2-31.5,14.1-71.2,10.4c-2.5-0.2-5-0.5-7.6-0.9c-18.1-3.8-34.7-9.3-50-16.1c-78.7-35.2-121.6-105-142-149.5V530 c12,10.3,18.8,17.2,18.8,17.2c51.6,56.7,105.7,87.9,152.5,104.9c11.1,4,21.8,7.3,32,9.7c0.1,0,0.1,0,0.2,0.1 c4.7,1.2,9.2,2.2,13.6,3.2c36.1,7.5,73.7,3.7,107.5-11.1c2.3-1,3.8-1.7,4.3-1.9c-1.7,2.2-22.2,26.4-59.6,40.4 c-2.4,0.9-4.7,1.8-7.2,2.5c-18,4.5-35.3,6.7-52.1,7.2c-101.6,3-179.2-58.7-210-87.8v67.3c27.4,6.9,44.9,14,44.9,14 c130.9,58.6,234.2,31.8,280.6,12.9c29.7-12.1,47.8-43.9,40.4-75c-8.6-36.4-41.9-66.9-59.5-80.9c-9.2-7.3-16-17.2-19.8-28.3 c-34.2-102.3,65.5-235.1,65.5-235.1c-14.2,61.4,52,127.6,52,127.6c94.5,104,0,226.9,0,226.9C561.5,870.6,325.2,757.2,325.2,757.2 c-11.9-6.6-23.7-12.1-35.5-16.8v153.9h501.1v-709H289.7z M547.3,355.4l-37.8-47.3l47.3-66.2l28.4,47.3L547.3,355.4z"
                        style={{ fill: '#d79f00', strokeWidth: '0px', strokeOpacity: 0 }}
                    />
                </g>
            </svg>
        </button>
    );
}
